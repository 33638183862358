import React, { useState } from 'react';
import './SearchEngineReference.css';
import { ReactComponent as Logo } from '../../Assets/logo.svg';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import Bombflow from '../../Assets/Bombflow.webp';
import { useMyContext } from '../../context/ProjectProvider';
import { useNavigate } from 'react-router-dom';

function SearchEngineReference() {
  const { t, changeLanguage, currentProject } = useMyContext();
  const navigate = useNavigate();
  const [sideBarIsCollapsed, setSideBarIsCollapsed] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  //const [selectedCountry, setSelectedCountry] = useState('US');

  const toggleSideBar = () => {
    setSideBarIsCollapsed(!sideBarIsCollapsed);
  };

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleCountrySelect = (countryCode) => {
    changeLanguage(countryCode);
    //const mappedCountry = countryCode === 'it' ? 'IT' : countryCode === 'en' ? 'US' : '';
    //setSelectedCountry(mappedCountry);
    setShowDropdown(false);
  };

  const mappedCountry = (countryCode) => {
    return countryCode === 'it' ? 'IT' : countryCode === 'en' ? 'US' : '';
  }

  const CountrySelector = () => (
    <div className="country-selector">
      <button className="country-button" onClick={toggleDropdown} title="Country of interest">
        {mappedCountry(currentProject.language)}
      </button>
      {showDropdown && (
        <div className="country-dropdown">
          <button onClick={() => handleCountrySelect('it')}>IT</button>
          <button onClick={() => handleCountrySelect('en')}>US</button>
        </div>
      )}
    </div>
  );

  const goToHome = () => {
    navigate('/'); // Navega a "/"
  };

  return (
    <div className="search-engine-reference-wrapper"> {/* Este es el contenedor padre */}
      {/* Estructura del Header */}
      <div className="header-container-search-engine">
        <header className="header-search-engine">
          <div
            className="header-left"
            style={{ cursor: 'pointer' }}
            onClick={goToHome} // Agregamos onClick para navegar al home
          >
            {/* Ícono (SVG) */}
            <Logo className="header-logo" />
            {/* Nombre */}
            <div className="header-title-search-engine" translate="no">Deeplayered - Docs</div>
          
          </div>
          <button
              className="collapse-toggle"
              onClick={toggleSideBar}
          >
              {sideBarIsCollapsed ? t('OpenSidebar') : t('CloseSidebar')}
          </button>
          <CountrySelector />
        </header>
      </div>
    <div className="search-engine-reference">
      {!sideBarIsCollapsed && (
          <nav className="sidebar">
              <ul>
                  <li><a href="#introduction">{t('Introduction')}</a></li>
                  <li>
                    <a href="#searchEngines">{t('SearchEngines')}</a>
                    <ul>
                      <li><a href="#centrifugal_pump">{t('CentrifugalPump')}</a></li>
                    </ul>
                  </li>
              </ul>
          </nav>
      )}
      <div className="documentation">
        <section id="introduction">
          
          <br/>
          <h2>{t('Introduction')}</h2>
          <p>{t('WelcometotheDeepLayered')}</p>
          <p>{t('TheDeepLayeredShopis')}</p>
          <h3>{t('CoreConcepts')}</h3>
          <ul>
            <li><strong>{t('SearchEnginesasFunctions')}</strong> {t('Thinkofeachsearchengine')}</li>
            <li><strong>{t('DynamicQueries')}</strong> {t('Yousimplyprovideyourneeds')}</li>
            <li><strong>{t('StandardizedResponses')}:</strong> {t('Allsearchenginesreturn')}</li>
          </ul>
          <h3>{t('WhoIsThisFor')}</h3>
          <p>{t('Professionalsandhobbyistslooking')}</p>
          <h3>{t('WhatYoullFindHere')}</h3>
          <ul>
            <li><strong>{t('SearchEngines1')}</strong> {t('Learnabouttheparameters')}</li>
          </ul>
        </section>
        <section id="search-engines">
          <h2>{t('SearchEngines')}</h2>
          <p>{t('Searchenginesformthecore')}</p>

          <section id="centrifugal_pump">
            <h3>{t('CentrifugalPump1')}</h3>
            <pre>
              <code>Search engine name: "centrifugal_pump"</code>
            </pre>
              <p>
                  <h4>{t('Description')}<br/></h4> 
                  {t('Computestheadditionalhead')}
              </p>
            <h4>{t('Parameters')}</h4>
            <table>
              <thead>
                <tr>
                  <th>{t('Parameter')}</th>
                  <th>{t('Type')}</th>
                  <th>{t('Required')}</th>
                  <th>{t('Description')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>freeText</td>
                  <td>{t('text')}</td>
                  <td>{t('Yes')}</td>
                  <td>{t('freeTextDescription')}</td>
                </tr>
                <tr>
                  <td>{t('flowrate')}</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>{t('flowrate_us_gpmDescription')}</td>
                </tr>
                <tr>
                  <td>{t('fluid_density')}</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>{t('fluid_densityDescription')}</td>
                </tr>
                <tr>
                  <td>{t('fluid_absolute_viscosity_cp')}</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>{t('fluid_absolute_viscosityDescription')}</td>
                </tr>
                <tr>
                  <td>{t('fluid_vapor_pressure')}</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>{t('fluid_vapor_pressureDescription')}</td>
                </tr>
                <tr>
                  <td>{t('suction_reservoir_pressure')}</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>{t('suction_reservoir_pressureDescription')}</td>
                </tr>
                <tr>
                  <td>{t('suction_static_head')}</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>{t('suction_static_headDescription')}</td>
                </tr>
                <tr>
                  <td>{t('suction_valves_and_fittings')}</td>
                  <td>{t('list')}</td>
                  <td>{t('Yes')}</td>
                  <td>
                      <p>{t('suction_valves_and_fittingsDescription')}</p>
                          <table>
                              <thead>
                                  <tr>
                                      <th>{t('accessory_type')}</th>
                                      <th>{t('number_of_units')}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>{t('Anyofthefollowingoptions')}<br/>["45_degree_elbow", "90_degree_regular_screwed_elbow", "90_degree_all_other_elbow", "globe_valve_screwed_wide_open", "globe_valve_flanged_wide_open", "angle_valve_wide_open", "swing_check_valve_flanged", "misc_with_k_equal_to_1"]</td>
                                      <td>{t('numberofunits')}</td>
                                  </tr>
                              </tbody>
                          </table>
                      <p>Example input: {JSON.stringify([{'accessory_type': '45_degree_elbow', 'count': 2}, {'accessory_type': 'globe_valve_screwed_wide_open', 'count': 1}])}. The misc_with_k_equal_to_1 option allows for representing custom accessories</p>
                  </td>
                </tr>
                <tr>
                  <td>suction_pipe_internal_diameter_inches</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>D1 as per Figure 1.1. Real Internal diameter as per guage.</td>
                </tr>
                <tr>
                  <td>suction_pipe_length_feet</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>L1 as per Figure 1.1. Total length of pipe from pump suction to suction reservoir</td>
                </tr>
                <tr>
                  <td>suction_pipe_wall_roughness</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>E1 as per Figure 1.1. Roughness (English system). Specific to each type of pipe, i.e., 0.00015 for Commercial steel, as per HI. A table with English system values can be found&nbsp;
                    <a href="https://datatool.pumps.org/fluid-flow-iii/general" target="_blank" rel="noopener noreferrer">here</a>.
                  </td>
                </tr>
                <tr>
                  <td>discharge_pressure_psia</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>P2 as per Figure 1.1. Absolute pressure in pounds per square inch. This would be the atmospheric pressure if discharge is to atmosphere</td>
                </tr>
                <tr>
                  <td>discharge_static_head_feet</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>h2 as per Figure 1.1. The vertical distance between the pump centerline and the fluid level at the discharge point, representing the elevation the pump must overcome. Positive if above the pump, negative if bellow the pump</td>
                </tr>
                <tr>
                  <td>discharge_valves_and_fittings</td>
                  <td>list</td>
                  <td>{t('Yes')}</td>
                  <td>
                      <p>A2 as per Figure 1.1. Accessories present in the suction pipe. Possibilities are:</p>
                          <table>
                              <thead>
                                  <tr>
                                      <th>accessory_type</th>
                                      <th>number_of_units</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>Any of the following options: <br/>["45_degree_elbow", "90_degree_regular_screwed_elbow", "90_degree_all_other_elbow", "globe_valve_screwed_wide_open", "globe_valve_flanged_wide_open", "angle_valve_wide_open", "swing_check_valve_flanged", "misc_with_k_equal_to_1"]</td>
                                      <td>number of units</td>
                                  </tr>
                              </tbody>
                          </table>
                      <p>An example would be: {JSON.stringify([{'accessory_type': '45_degree_elbow', 'count': 2}, {'accessory_type': 'globe_valve_screwed_wide_open', 'count': 1}])}. The misc_with_k_equal_to_1 can be used to represent any custom accessory</p>
                  </td>
                </tr>
                <tr>
                  <td>discharge_pipe_internal_diameter_inches</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>D2 as per Figure 1.1. Real Internal diameter as per guage.</td>
                </tr>
                <tr>
                  <td>discharge_pipe_length_feet</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>L2 as per Figure 1.1. Total length of pipe from pump discharge to discharge pipe output.</td>
                </tr>
                <tr>
                  <td>suction_pipe_wall_roughness</td>
                  <td>{t('number')}</td>
                  <td>{t('Yes')}</td>
                  <td>E2 as per Figure 1.1. Roughness (English system). Specific to each type of pipe, i.e., 0.00015 for Commercial steel, as per HI. A table with English system values can be found&nbsp;
                    <a href="https://datatool.pumps.org/fluid-flow-iii/general" target="_blank" rel="noopener noreferrer">here</a>.
                  </td>
                </tr>
                <tr>
                  <td>product_ids_to_compare_against</td>
                  <td>list</td>
                  <td>Optional</td>
                  <td>A list of product ids. This can be useful if one wants to compute the duty point and system characteristics for a pre-determined list of pumps</td>
                </tr>
              </tbody>
            </table>
            <h4>{t('Computations')}</h4>
            <p>{t('Thefollowingcomputationsare')}&nbsp;
              <a href="https://datatool.pumps.org/" target="_blank" rel="noopener noreferrer">here</a>.
            </p>
            <p>
            <figure style={{ textAlign: 'center' }}>
              <img
                  src={Bombflow}
                  alt="Parameter"
                  style={{
                      maxWidth: '300px',
                      marginRight: '0px',
                      verticalAlign: 'middle',
                  }}
              />
              <figcaption style={{ marginTop: '8px', fontSize: '14px', color: '#b0b0b0' }}>
                  {t('Figure1')}
              </figcaption>
            </figure>
            </p>
            <strong>{t('Parameterabreviations')}</strong>
              <div style={{ textAlign: 'left'}}>
                <BlockMath math={`
                  \\begin{aligned}
                  \\rho &: \\text{fluid\\_density\\_lbs\\_per\\_cubic\\_foot} \\\\
                  \\mu &: \\text{fluid\\_absolute\\_viscosity} \\\\
                  P_v &: \\text{fluid\\_vapor\\_pressure\\_psia} \\\\
                  Q &: \\text{flowrate\\_US\\_GPM} \\\\
                  h1 &: \\text{suction\\_static\\_head\\_feet} \\\\
                  P1 &: \\text{suction\\_reservoir\\_pressure\\_psia} \\\\
                  D1 &: \\text{suction\\_pipe\\_internal\\_diameter\\_inches} \\\\
                  L1 &: \\text{suction\\_pipe\\_length\\_feet} \\\\
                  A1 &: \\text{valves\\_and\\_fittings\\_suction\\_pipe} \\\\
                  \\epsilon1 &: \\text{suction\\_pipe\\_wall\\_roughness} \\\\
                  h2 &: \\text{discharge\\_static\\_head\\_feet} \\\\
                  P2 &: \\text{discharge\\_pressure\\_psia} \\\\
                  D2 &: \\text{discharge\\_pipe\\_internal\\_diameter\\_inches} \\\\
                  L2 &: \\text{discharge\\_pipe\\_length\\_feet} \\\\
                  A2 &: \\text{valves\\_and\\_fittings\\_discharge\\_pipe} \\\\
                  \\epsilon2 &: \\text{discharge\\_pipe\\_wall\\_roughness} \\\\

                  \\end{aligned}
                  
                  `
                } />
                
              </div>
            <br/>
            <strong>{t('Equations')}</strong>
            <br/>
            <p style={{textIndent: '30px'}}>{t('PumpHeadand')}</p>
            <p style={{textIndent: '60px'}}>{t('Hatpumpsuctionflange')}</p>

            <div className='equation-box'>
              <BlockMath>
                  {`H_{suction\\ flange} =  H_{p1} - H_{z1} - H_{f1p} - H_{f1a}`}
              </BlockMath>
            </div>


            <BlockMath>
                {`H_{p1} = \\frac{P1\\cdot2.31}{\\rho/62.4}`}
            </BlockMath>
            <BlockMath>
                {`H_{z1} = h1`}
            </BlockMath>

            <BlockMath>
                {`H_{f1p} = f\\cdot\\frac{L1}{D1}\\cdot\\frac{v1^2}{2\\cdot g},\\ f = F(D2, v1, \\rho, \\epsilon1, \\mu)`}
            </BlockMath>
            
            <BlockMath>
                {`H_{f1a} =  F(A1, ND1, v1)`}
            </BlockMath>

            <p style={{textIndent: '60px'}}>Where:</p>

            <BlockMath math={`
              \\begin{aligned}
              H_{suction\\ flange} &: \\text{Head at suction flange of pump}\\ (ft) \\\\
              H_{p1} &: \\text{Pressure head on suction side}\\ (ft) \\\\
              H_{z1} &: \\text{Elevation head on suction side}\\ (ft) \\\\
              H_{f1p} &: \\text{Pipe friction head on suction side}\\ (ft) \\\\
              f &: \\text{Darcy-Weisbach friction factor as per Serghide's method} \\\\
              H_{f1a} &: \\text{Valves and fittings friction head on suction side}\\ (ft) \\\\
              v1 &: \\text{Suction\\ pipe\\ fluid\\ velocity}\\ (ft/s) \\\\
              \\end{aligned}

              `
            }/>

            <br/>
            <p style={{textIndent: '60px'}}>{t('Hatpumpdischargeflange')}</p>

            <div className='equation-box'>
              <BlockMath>
                  {`H_{discharge\\ flange} =  H_{p2} - H_{z2} - H_{f2p} - H_{f2a}`}
              </BlockMath>
            </div>

            <BlockMath>
                {`H_{p2} = \\frac{P2\\cdot2.31}{\\rho/62.4}`}
            </BlockMath>
            <BlockMath>
                {`H_{z2} = h2`}
            </BlockMath>

            <BlockMath>
                {`H_{f2p} = f\\cdot\\frac{L2}{D2}\\cdot\\frac{v2^2}{2\\cdot g},\\ f = F(D2, v2, \\rho, \\epsilon2, \\mu)`}
            </BlockMath>
            
            <BlockMath>
                {`H_{f2a} =  F(A2, ND2, v2)`}
            </BlockMath>

            <p style={{textIndent: '60px'}}>Where:</p>

            <BlockMath math={`
              \\begin{aligned}
              H_{discharge\\ flange} &: \\text{Head at discharge flange of pump}\\ (ft) \\\\
              H_{p2} &: \\text{Pressure on suction side}\\ (ft)  \\\\
              H_{z2} &: \\text{Elevation head on suction side}\\ (ft)  \\\\
              H_{f2p} &: \\text{Pipe friction head on suction side}\\ (ft)  \\\\
              f &: \\text{Darcy-Weisbach friction factor as per Serghide's method} \\\\
              H_{f2a} &: \\text{Valves and fittings friction head on discharge side}\\ (ft)  \\\\
              v2 &: \\text{Discharge\\ pipe\\ fluid\\ velocity} (ft/s) \\\\
              \\end{aligned}

              `
            }/>

            

            <br/>

            <p style={{textIndent: '60px'}}>{t('PumpHead')}</p>

            
            <div className='equation-box'>
              <BlockMath>
                {`H_{pump} = H_{discharge\\ flange} - H_{suction\\ flange}`}
              </BlockMath>
            </div>

            <div className='equation-box'>
              <BlockMath>
                {`P_{pump} = \\frac{H_{pump}}{2.31} \\cdot \\frac{\\rho}{62.4}`}
              </BlockMath>
            </div>

            

            
            <br/>

            <p style={{textIndent: '30px'}}>{t('BreakHorsepower')}</p>

            <div className='equation-box'>

              <BlockMath>
                {`BHP = H_{pump} \\cdot Q \\cdot \\frac{\\rho/62.4}{3690 \\cdot Efficiency}`}
              </BlockMath>
            </div>

            <p style={{textIndent: '60px'}}>Where:</p>

            <BlockMath math={`
              \\begin{aligned}
              BHP &: \\text{Break Horsepower} \\\\
              H_{pump} &: \\text{Head performed on fluid by pump}\\ (ft)  \\\\
              P_{pump} &: \\text{Pressure performed on fluid by pump}\\ (psia) 
              \\end{aligned}

              `
            }/>
            <br/>

            <p style={{textIndent: '30px'}}>{t('NetPositiveSuctionHeadavailable')}</p>

            <div className='equation-box'>
              <BlockMath>
                {`NPSH_a = \\frac{(Psia\\_suction - P_v) \\cdot 2.31}{\\rho/62.4}`}
              </BlockMath>
            </div>

            <BlockMath>
              {`Psia\\_suction = \\frac{H_{suction\\ flange}}{2.31} \\cdot \\frac{\\rho}{62.4}`}
            </BlockMath>

            <p style={{textIndent: '60px'}}>Where:</p>

            <BlockMath math={`
              \\begin{aligned}
              NPSH_a &: \\text{Net positive suction head available} \\\\
              Psia\\_suction &: \\text{Aboslute pressure at suction flange}\\ (psia) \\\\
              \\end{aligned}

              `
            }/>

            
            <br/>

            <h4>{t('ResponseFormat')}</h4>
            <p>{t('WhenyouusetheDeepLayeredShops')}</p>
            <pre>
              
              <h4>1. Ideal Duty Point</h4>
              <ul>
                  <li><p><strong>Pump Head (H)</strong>: The ideal operating head the pump would achieve, measured in feet.</p></li>
                  <li><p><strong>Pressure equivalent (psia):</strong>: Pressure equivalent of H</p></li>
                  <li><p><strong>Flowrate (GPM)</strong>: The ideal volume of fluid the pump would move, measured in gallons per minute.</p></li>
              </ul>
              

              <h4>2. System Characteristics</h4>
              <p>At the ideal duty point, the system provides additional computed details that help you make an informed decision:</p>
              <ul>
                  <li><strong>Net Positive Suction Head Available (NPSHa)</strong>: <br/>Head equivalent for the difference between the pressure at suction flange and liquid's vapor pressure.<br/>Intuitively, it represents the 'available' pressure difference before cavitation ocurrs.</li>
                  <li><strong>Brake Horsepower (BHP)</strong>: The power required to operate the pump at the ideal duty point. This does not account for motor Hp</li>
                  <li><strong>Other Factors</strong>: Depending on your input, additional relevant characteristics may be included.</li>
              </ul>

              <h4>3. Recommended Products</h4>
              <p>A list of pumps that most approximate your ideal requirements is presented, along with specific details for each product:</p>
              <ul>
                  <li><strong>Pump Name & Model</strong>: The name and model of the pump.</li>
                  <li><strong>Calculated Duty Point</strong>: The estimated head and flowrate with this pump</li>
                  <li><strong>System Characteristics</strong>: Values like NPSH_a, BHP, and more.</li>
                  <li><strong>Euclidean Distance</strong>: A measurement of how closely the product matches your ideal requirements (lower is better).</li>
                  <li><strong>Suction & Discharge Types</strong>: Connection types (e.g., FNPT, MNPT, flanged) and sizes for both suction and discharge.</li>
                  <li><strong>Price & Purchase Link</strong>: A direct link to buy the product.</li>
              </ul>

              <h4>Notes:</h4>
              <ul>
                  <li>All products listed are carefully selected based on the ideal duty point and system characteristics, computed with the parameter values submitted to the search engine.</li>
                  <li>The Euclidean distance helps you identify how well each product meets your exact specifications. A lower value indicates a closer match.</li>
              </ul>
            </pre>
          </section>
        </section>
      </div>
    </div>
    </div>
  );
}

export default SearchEngineReference;
